import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '@/views/Layout.vue'
import Home from '@/views/home/index'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Layout',
    component: Layout,
    redirect: '/home',
    children: [
      {
        path: '/home',
        name: 'Home',
        component: Home,
        meta: {
          title: '风铃笔记',
          icon: 'el-icon-s-home',
          keepAlive: true
        }
      },
      {
        path: '/search',
        name: 'Search',
        component: () => import('../views/search/index'),
        meta: {
          title: '文章列表',
          icon: 'el-icon-search',
          keepAlive: true
        }
      },
      // {
      //   path: '/detail',
      //   name: 'Detail',
      //   component: () => import('../views/detail/index'),
      //   meta: {
      //     title: '文章详情',
      //     icon: 'el-icon-document',
      //     keepAlive: false
      //   }
      // },
      {
        // 使用params传参
        path: '/article/detail/:articleId',
        name: 'ArticleDetail',
        component: () => import('../views/detail/index'),
        meta: {
          title: '文章详情',
          icon: 'el-icon-document',
          keepAlive: false
        }
      },
      {
        path: '/about',
        name: 'About',
        component: () => import('../views/about/index'),
        meta: {
          title: '关于',
          icon: 'el-icon-info',
          keepAlive: true
        }
      }
    ]
  },
  {
    path: '/login',
    name: 'Login',
    meta: {
      title: '登陆 | 风铃笔记',
    },
    component: () => import('../views/login')
  },
  {
    path: '/register',
    name: 'Register',
    meta: {
      title: '欢迎注册 | 风铃笔记',
    },
    component: () => import('../views/register')
  }
]

// 防止连续点击多次路由报错
let routerPush = VueRouter.prototype.push;
let routerReplace = VueRouter.prototype.replace;
// push
VueRouter.prototype.push = function push(location) {
    return routerPush.call(this, location).catch(err => err)
}
// replace
VueRouter.prototype.replace = function push(location) {
    return routerReplace.call(this, location).catch(err => err)
}

const createRouter = () => new VueRouter({
  mode: 'history',
  // base: process.env.BASE_URL,
  routes,
  // 设置页面滚动到指定位置  https://router.vuejs.org/zh/guide/advanced/scroll-behavior.html
  scrollBehavior (to, from, savedPosition) {
    // 始终滚动到顶部
    // return { x: 0, y: 0 }
    if (to.path !== '/home' && to.path !== '/search') {
      return { x: 0, y: 0 }
    }
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  }
})

const router = createRouter()

export function resetRouter () {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher
}

export default router
