<template>
  <div id="app">
    <router-view/>
    <!--<layout></layout>-->
  </div>
</template>

<script>
import Layout from "@/views/Layout";
export default {
  name: 'App',
  components: {Layout},

  provide(){
    return{
      reload:this.reload
    }
  },

  data(){
    return{
      isRouterAlive:true
    }
  },

  methods:{
    reload(){
      this.isRouterAlive = false;
      this.$nextTick(function () {
        this.isRouterAlive = true;
      });
    }
  },
}
</script>

<style lang="less">
body {
  margin: 0;
  padding: 0;
  height: 100vh;
  background: #fff;
}
#app {
  //font-family: Avenir, Helvetica, Arial, sans-serif;
  //-webkit-font-smoothing: antialiased;
  //-moz-osx-font-smoothing: grayscale;
  //text-align: center;
  //color: #2c3e50;
}

.main-container {
  min-height: calc(100vh - 136px);
  margin-top: 55px;
}

.el-pagination {
  white-space: pre-wrap;
}
.el-message-box {
  width: 320px;
 }

</style>

