import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './permission'
import plugins from './plugins'
import './assets/iconfont/iconfont.css'
import VueMeta from 'vue-meta'

import {
    Container,
    Header,
    Main,
    Footer,
    Menu,
    MenuItem,
    Submenu,
    Button,
    Input,
    Select,
    Backtop,
    Icon,
    Image,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    Row,
    Col,
    Drawer,
    Loading,
    Card,
    Tag,
    Empty,
    Divider,
    Avatar,
    Pagination,
    Form,
    FormItem,
    Checkbox,
    Steps,
    Step,
    Dialog, Result, Tooltip
} from 'element-ui';

// import mavonEditor from 'mavon-editor'
// import 'mavon-editor/dist/css/index.css'
// Vue.use(mavonEditor);

Vue.use(Container)
Vue.use(Header)
Vue.use(Main)
Vue.use(Footer)
Vue.use(Menu)
Vue.use(MenuItem)
Vue.use(Submenu)
Vue.use(Button)
Vue.use(Input)
Vue.use(Select)
Vue.use(Backtop)
Vue.use(Icon)
Vue.use(Image)
Vue.use(Dropdown)
Vue.use(DropdownMenu)
Vue.use(DropdownItem)
Vue.use(Row)
Vue.use(Col)
Vue.use(Drawer)
Vue.use(Loading)
Vue.use(Card)
Vue.use(Tag)
Vue.use(Empty)
Vue.use(Divider)
Vue.use(Avatar)
Vue.use(Pagination)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(Checkbox)
Vue.use(Steps)
Vue.use(Step)
Vue.use(Dialog)
Vue.use(Result)
Vue.use(Tooltip)

Vue.use(plugins)

Vue.use(VueMeta)

import MD5 from 'js-md5';
Vue.prototype.md5 = MD5

// 缺点：都不能使用页面异步加载的数据，除非在页面渲染完之前加载好数据
//全局自定义指令，修改单个页面标题，使用：在view 的最外层标签上使用该指令：v-title data-title="xxx" 即可设置当前页面标题
// Vue.directive('title', {
//     inserted: function (el, binding) {
//         // document.title = el.dataset.title // 使用方式：v-title data-title="xxx"
//         document.title = el.getAttribute('title') // 使用方式：v-title title="xxx"
//     }
// })

Vue.config.productionTip = false

document.title = '风铃笔记'

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
