import router from './router'
import store from './store'
import { Message } from 'element-ui'
import NProgress from 'nprogress'
// import 'nprogress/nprogress.css'
import './assets/style/nprogress.css'
import { getToken } from '@/utils/auth'
import { isRelogin } from '@/utils/request'

NProgress.configure({ showSpinner: false })

const whiteList = ['/', '/home', '/login', '/register', '/detail', '/search', '/about']

router.beforeEach((to, from, next) => {
  NProgress.start()
  document.title = to.meta.title || '风铃笔记' //在全局后置守卫中获取路由元信息设置titl
  if (getToken()) {
    to.meta.title && store.dispatch('settings/setTitle', to.meta.title)
    /* has token*/
    if (to.path === '/login') {
      next({ path: '/' })
      NProgress.done()
    }
    // else if (whiteList.indexOf(to.path) !== -1) {
    //   next()
    // }
    else {
      if (store.getters.name === '') {
        isRelogin.show = true
        // 判断当前用户是否已拉取完user_info信息
        store.dispatch('GetInfo').then(() => {
          isRelogin.show = false
          // next({ ...to, replace: true })
          next()
        }).catch(err => {
            store.dispatch('LogOut').then(() => {
              Message.error(err)
              next({ path: '/' })
            })
          })
      } else {
        next()
      }
    }
  } else {
    // 没有token
    if (whiteList.indexOf(to.path) !== -1) {
      // 在免登录白名单，直接进入
      next()
    } else if (to.path.includes('/article/detail')) {
      // 这里使用route.params传参，所以path为 /article/detail/${articleId}，所以单独判断一下
      next()
    } else {
      next(`/login?redirect=${encodeURIComponent(to.fullPath)}`) // 否则全部重定向到登录页
      NProgress.done()
    }
  }
})

router.afterEach(() => {
  NProgress.done()
})
