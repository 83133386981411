<template>
  <div class="main-container">
    <!--<div :style="{-->
    <!--      backgroundImage:'url(' + bgUrl + ')',-->
    <!--      backgroundRepeat:'no-repeat',-->
    <!--      // backgroundSize:'100% 100%'-->
    <!--      backgroundSize:'cover'-->
    <!--      // backgroundSize:'cover'-->
    <!--    }" style="height: calc(100vh - 70px); width: 100%; margin-bottom: 20px; display: flex; justify-content: center; align-items: center">-->
    <!--  <div>-->
    <!--      <span style="font-size: 20px; color: rgba(0,0,0,0.74)">{{ oneSentence }}</span>-->
    <!--  </div>-->
    <!--</div>-->

    <el-row style="width: 100%">
      <el-col :xs="24" :sm="24" :md="{span: 22, offset: 1}" :lg="{span: 18, offset: 3}" :xl="{span: 18, offset: 3}">
        <el-row :gutter="20" style="margin-left: 0; margin-right: 0;">

          <el-col :span="24" :xs="24" :sm="24" :md="18" :lg="18" :xl="18">
            <el-empty v-if="articleList.length === 0"></el-empty>
            <template v-else v-for="item in articleList">
              <ArticleItem :article="item"/>
            </template>
            <el-pagination
                v-show="total>0"
                background
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page.sync="queryParams.pageNum"
                :page-sizes="[2, 3, 5, 10, 20, 30, 50]"
                :page-size.sync="queryParams.pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="total">
            </el-pagination>
          </el-col>
          <el-col :span="24" :xs="24" :sm="24" :md="6" :lg="6" :xl="6" style="margin-bottom: 20px">
            <el-card>
              <div style="display: flex; flex-direction: row; align-items: center;justify-content: center">
                <el-avatar :src="avatar" :size="100" fit="scale-down">{{ baseInfo.nickName }}</el-avatar>
                <div style="display: flex; flex-direction: column; align-items: center; width: 50%;">
                  <span style="font-size: 50px">{{baseInfo.nickName}}</span>
                  <!--<div style="display: flex; flex-direction: row;">-->
                  <!--  <span style="margin-right: 10px;">文章{{baseInfo.articleNum}}</span>-->
                  <!--  <span>浏览{{baseInfo.viewNum}}</span>-->
                  <!--</div>-->
                </div>
              </div>
            </el-card>
            <el-card>
              <div style="display: flex; flex-direction: row; justify-content: space-evenly;">
                <span style="margin-right: 10px;">文章{{baseInfo.articleNum}}</span>
                <span>浏览{{baseInfo.viewNum}}</span>
              </div>
            </el-card>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
  </div>
</template>

<script>
// @ is an alias to /src
import {getArticleList, getBaseInfo} from "@/api/articles";
import ArticleItem from "@/components/ArticleItem";
import {getRandomSentence} from "@/api";
import {scrollTo} from "@/utils/scrollTo";
import config from '@/config'

export default {
  name: 'HomeView',
  components: {
    ArticleItem
  },
  data() {
    return {
      queryParams: {
        pageNum: 1,
        pageSize: 10
      },
      articleList: [],
      total: 0,
      baseInfo: {},
      avatar: '',
      bgUrl: require('@/assets/img/home-bg.png'),
      oneSentence: ''
    };
  },
  created() {
    // this.getOne();
    this.getBaseInfo();
    this.getArticleList();
  },
  computed: {
    baseUrl() {
      return process.env.NODE_ENV === 'development' ? config.baseUrl.dev : config.baseUrl.pro;
    }
  },
  metaInfo() {
    return {
      title: this.pageTitle,
      meta: [
        { name: 'keywords', content: '风铃笔记, 首页, 笔记网站, 欢迎' },
        { name: 'description', content: '风铃笔记首页,文章列表' }
      ]
    };
  },
  methods: {
    handleSizeChange() {
      this.queryParams.pageNum = 1;
      this.getArticleList();
      this.scrollTo();
    },
    handleCurrentChange() {
      this.getArticleList();
      this.scrollTo();
    },
    scrollTo() {
      this.$nextTick(() => {
        let height = document.body.clientHeight - 70
        scrollTo(height, 0);
      });
    },
    // 随机语录
    getOne() {
      getRandomSentence().then(res=>{
        this.oneSentence = res.data.content
      })
    },
    getBaseInfo() {
      getBaseInfo().then(res => {
        this.baseInfo = res.data
        this.avatar = this.baseUrl + this.baseInfo.avatar;
      }).catch(()=>{});
    },
    // 查询文章
    getArticleList() {
      getArticleList(this.queryParams).then(res => {
        this.articleList = res.data.rows
        this.total = res.data.total
        if (this.queryParams.pageNum === 1 && this.queryParams.pageSize === 10) {
          this.$store.dispatch('article/setRecentArticleList', res.data.rows)
        }
      }).catch(()=>{});
    },
    go() {
      this.$router.push('/about')
    }
  }
}
</script>
<style lang="less" scoped>
.main-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;

  .top-panel {
    width: 100%;
    text-align: center;
  }

  .main-panel {
    width: 80%;
    //display: flex;
    //justify-content: center;
  }
}
</style>
